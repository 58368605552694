import type {
  GetInstitutionsByTagQuery,
  GetInstitutionsByTagQueryVariables
} from './getInstitutionsByTag.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetInstitutionsByTag($tagListId: ID!, $search: String) {
    tagListInstitutions(tagListId: $tagListId, search: $search) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getTagListInstitutionParticipants = ({
  tagListId,
  search
}: GetInstitutionsByTagQueryVariables) => {
  const { loading, result, refetch } = useQuery<
    GetInstitutionsByTagQuery,
    GetInstitutionsByTagQueryVariables
  >(query, { search, tagListId })

  const institutions = computed(() => result.value?.tagListInstitutions?.collection || [])

  console.log('getTagListInstitutionParticipants', institutions.value)
  return { loading, institutions, refetch }
}
