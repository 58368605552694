import mitt from 'mitt'

// Holds all events that can be emitted on the BUS
// The key is the event name and the value describes the payload shape
type PossibleBusEvents = {
  // The payload can be an object
  // PersonUpdated: { id: string }
  // or a simple scalar
  // PageViewsIncreased: number
  AffiliationTypeCreated: {}
  AffiliationTypeDeleted: {}
  AffiliationsUpdated: { institutionId: string }
  CapacityCreated: {}
  CapacityDeleted: {}
  ContactKindCreated: {}
  ContactKindDeleted: {}
  CostCenterCreated: {}
  CostCenterDeleted: {}
  EventParticipationSaving: {}
  EventParticipantsListUpdated: {}
  EventTypeCreated: {}
  EventTypeDeleted: {}
  GroupTypeCreated: {}
  GroupTypeDeleted: {}
  InstitutionMembershipsUpdated: { institutionId: string }
  PersonRelationshipsUpdated: { personId: string }
  PersonTitleCreated: {}
  PersonTitleDeleted: {}
  RelationshipTypeCreated: {}
  RelationshipTypeDeleted: {}
  RoomTypeCreated: {}
  RoomTypeDeleted: {}
  TagCreated: {}
  TagDeleted: {}
  EventTransportLocationsUpdated: { eventId: string }
  EventGroupsUpdated: { eventId: string }
  SubEventsUpdated: { eventId: string }
  OpenParticipationModal: { eventId: string; participationId: string }
  EventPresetsUpdated: { eventId: string }
  FormListUpdated: { eventId: string }
  EmailDeleted: {}
  ReleaseNotesUpdated: {}
  EventsListUpdated: {}
  RoomTablesUpdated: { roomId: string }
  TableSelected: {}
  PeopleListUpdated: {}
  VotingSlotsUpdated: {}
  ParticipantVotingKeyUpdated: {}
}

const bus = mitt<PossibleBusEvents>()

// Use to listen to an event on the BUS
// For example:
//
// onBusEmit('PersonUpdated', ({ id }) => alert(`Yay! Person with ID ${id} was updated!`))
export const onBusEmit = bus.on

// Use to emit events on the BUS
// For example:
//
// emitOnBus('PersonUpdated', { id: '123' })
export const emitOnBus = bus.emit
