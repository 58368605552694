import type {
  GetAllEventCapacitiesQuery,
  GetAllEventCapacitiesQueryVariables
} from './getAllEventCapacities.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventCapacities($eventId: ID!) {
    event(id: $eventId) {
      id
      capacities {
        id
        capacity {
          id
          name
        }
      }
    }
  }
`

export const getEventCapacities = (eventId: string) => {
  const { loading, result, refetch } = useQuery<
    GetAllEventCapacitiesQuery,
    GetAllEventCapacitiesQueryVariables
  >(query, { eventId })

  const capacities = computed(() => result.value?.event?.capacities || [])

  return { loading, capacities, refetch }
}
